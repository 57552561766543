@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure background color and border is applied to select elements */
select {
  background-color: white !important;
  border: 1px solid #4B5563 !important; /* Darker gray border (Tailwind's gray-600) */
  color: #374151 !important; /* Font color (Tailwind's gray-700) */
}

/* Apply background color, border, and font color to all text input fields */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
/*input[type="checkbox"],*/
input[type="tel"],
textarea {
  background-color: white !important;
  border: 1px solid #4B5563 !important; /* Darker gray border */
  color: #374151 !important; /* Font color (Tailwind's gray-700) */
}

/* Ensure placeholders have the same font color */
input::placeholder,
textarea::placeholder {
  color: #374151 !important; /* Placeholder font color (Tailwind's gray-700) */
}

/* Custom styling for the quill editor wrapper */
.quill-editor-wrapper {
    margin-bottom: 50px; /* Adds space between the editor and buttons */
}

/* Custom navbar styles */
.custom-navbar {
  @apply bg-white shadow-md fixed w-full z-20;
  height: 64px;
}

.custom-navbar-content {
  @apply mx-auto px-4 sm:px-6 lg:px-8 flex justify-between h-16 items-center;
  max-width: 98%; /* Expand to 98% of the screen width */
}

.custom-navbar-link {
  @apply px-3 py-2 rounded-md text-sm font-medium;
}

.custom-navbar-link-active {
  @apply text-cyan-700;
}

.custom-navbar-link-inactive {
  @apply text-gray-700 hover:text-cyan-700;
}

.custom-navbar-mobile-menu-button {
  @apply inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-700;
}

.custom-navbar-mobile-menu-content {
  @apply px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white shadow-md;
}

/* Ensure proper spacing between navbar elements on mobile */
@media (max-width: 767px) {
  .custom-navbar-mobile-menu-content {
    position: absolute !important;
    top: 64px !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 30 !important;
  }

  .custom-navbar-mobile-menu-content a {
    display: block !important;
    padding: 0.5rem 1rem !important;
  }
}

/* Sidebar styles */
.sidebar-component {
  position: fixed !important;
  left: 0 !important;
  top: 64px !important; /* Should match the navbar height */
  height: calc(100vh - 64px) !important;
  z-index: 10 !important;
}

/* Main content styles */
.main-content {
  padding-top: 0.2rem !important;
  margin-left: 24px !important;
}

/* Ensure the sidebar is behind the navbar when expanded */
.sidebar-component:hover {
  z-index: 15 !important;
}

/* Adjust sidebar width when expanded */
.sidebar-component:hover > div:last-child {
  width: 256px !important;
}

/* Center the arrow in the sidebar */
.sidebar-component > div:first-child {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 24px !important;
}

/* Layout container styles */
.layout-container {
  padding-top: 64px; /* Match the navbar height */
}

/* News container styles */
.news-container {
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 1rem;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 1rem auto;
  /*width: calc(100% - 0.5rem);*/
  max-width: 100%;
}

.news-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.news-item {
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.news-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #1f2937;
}

.news-date {
  font-size: 0.75rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.news-body {
  font-size: 0.875rem;
  color: #374151;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .overflow-x-auto {
    -webkit-overflow-scrolling: touch;
  }

  .news-container {
    max-height: none;
    width: calc(100% - 1rem);
    margin: 0.5rem auto;
  }

  .main-content {
    margin-left: 8px !important;
  }
}






/* Scripture Banner styles */
.scripture-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  height: 60px; /* Increased height */
  background-color: #f3f4f6; /* Light gray background */
  border-bottom: 1px solid #e5e7eb; /* Light border for separation */
}

.scripture-banner .carousel {
  height: 100%;
}

.scripture-banner .carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
}

.scripture-banner p {
  font-size: 1rem; /* Consistent font size */
  color: #374151; /* Dark gray color for better readability */
  font-weight: 500; /* Medium weight for better visibility */
  line-height: 1.4;
  text-align: center;
  margin: 0;
}

/* Adjust existing navbar styles */
.custom-navbar {
  top: 60px; /* Adjusted to match new banner height */
}

/* Adjust layout container styles */
.layout-container {
  padding-top: 124px; /* 64px (navbar) + 60px (banner) */
}

/* Adjust sidebar styles */
.sidebar-component {
  top: 124px !important; /* 64px (navbar) + 60px (banner) */
  height: calc(100vh - 124px) !important;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .scripture-banner {
    height: 80px; /* Even taller on mobile for better readability */
  }

  .scripture-banner p {
    font-size: 0.875rem; /* Slightly smaller font on mobile */
  }

  .custom-navbar {
    top: 80px; /* Adjusted for taller banner on mobile */
  }

  .layout-container {
    padding-top: 144px; /* 64px (navbar) + 80px (banner) */
  }

  .sidebar-component {
    top: 144px !important; /* 64px (navbar) + 80px (banner) */
    height: calc(100vh - 144px) !important;
  }
}